<template>
    <div class="resultPage">
        <img src="../../assets/signFailed.png" />
        <span class="title">报名缴费有误</span>

        <span class="desc">请重新提交报名</span>
        <Button class="mar-t-40" color="#2b72ff" block @click="submitAgain">再次提交</Button>
        <Button color="#2b72ff" plain block @click="goListPage" class="mar-t-15">返回培训首页</Button>
  </div>
</template>

<script>
import { Button } from "vant";
    export default {
        name:'signFailed',
        components:{Button},
        methods:{
            submitAgain(){
                this.$router.go(-1)
            },
            goListPage(){
                this.$router.push("/meetList")
            },
        }
    }
</script>

<style lang="less" scoped>
@import "./common.less";
</style>
